var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_vm._m(0),_vm._m(1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSave)}}},[_c('div',{staticClass:"card biz-primary"},[_c('div',{staticClass:"card-header"},[_vm._v(" 申込用紙送付先 ")]),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v("送付先情報をご確認ください。変更がある場合は上書きしてください。ご確認いただきましたら、「申込用紙の送付依頼」ボタンを押してください。")]),_c('div',{staticClass:"biz-form-group seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("郵便番号 (ハイフン有)")]),_c('div',{staticClass:"biz-form-body",staticStyle:{"display":"flex"}},[_c('b-form-group',{staticClass:"biz-input-group col-3"},[_c('validation-provider',{attrs:{"name":"郵便番号","rules":"required|numeric|length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"例）1060032"},model:{value:(_vm.form.postalCode),callback:function ($$v) {_vm.$set(_vm.form, "postalCode", $$v)},expression:"form.postalCode"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-2"},[_c('j-button',{staticClass:"ml-2",attrs:{"variant":"light"},on:{"click":function($event){return _vm.onAutoAddress()}}},[_vm._v("住所自動入力")])],1)],1)]),_c('div',{staticClass:"biz-form-group mt-2 seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("住所")]),_c('div',{staticClass:"biz-form-body"},[_c('b-form-group',{staticClass:"biz-input-group col-10"},[_c('validation-provider',{attrs:{"name":"住所","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"biz-form-group mt-2 seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("会社名")]),_c('div',{staticClass:"biz-form-body"},[_c('b-form-group',{staticClass:"biz-input-group col-8"},[_c('validation-provider',{attrs:{"name":"会社名","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"例）株式会社ワタベ"},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"biz-form-group mt-2 seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("部署名")]),_c('div',{staticClass:"biz-form-body"},[_c('b-form-group',{staticClass:"biz-input-group col-8"},[_c('validation-provider',{attrs:{"name":"部署名","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"例）営業部"},model:{value:(_vm.form.departmentName),callback:function ($$v) {_vm.$set(_vm.form, "departmentName", $$v)},expression:"form.departmentName"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"biz-form-group mt-2"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("担当者")]),_c('div',{staticClass:"biz-form-body"},[_c('b-form-group',{staticClass:"biz-input-group col-8"},[_c('validation-provider',{attrs:{"name":"担当者","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"例）鈴木太郎"},model:{value:(_vm.form.personInCharge),callback:function ($$v) {_vm.$set(_vm.form, "personInCharge", $$v)},expression:"form.personInCharge"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])]),_c('div',{staticClass:"text-center mt-5"},[_c('j-button',{attrs:{"variant":"primary","size":"lg","buttonType":"submit"}},[_vm._v(" 次へ ")])],1)])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title"},[_c('h1',[_vm._v("口座振替申込用紙送付先の入力")]),_c('p',[_vm._v("ビズハイウェイの利用料をお振込みいただくためのご準備をお願いいたします。")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 p-5"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("口座振替依頼の申込書")]),_c('p',[_vm._v("ビズハイウェイの利用料は口座振替でお支払いいただきます。")]),_c('p',[_vm._v("口座振替サービス提供会社はみずほファクター、SMBCファイナンスサービスいずれかからご選択いただきます。")]),_c('p',[_vm._v("申込用紙は両方とも送付させていただきますので、どちらかをご選択いただき、ご記入、押印して、ご返送ください。")])])}]

export { render, staticRenderFns }