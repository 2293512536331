<template>
  <div class="page">
    <div class="page-title">
      <h1>口座振替申込用紙送付先の入力</h1>
      <p>ビズハイウェイの利用料をお振込みいただくためのご準備をお願いいたします。</p>
    </div>
    <div class="mt-2 p-5">
      <p class="font-weight-bold">口座振替依頼の申込書</p>
      <p>ビズハイウェイの利用料は口座振替でお支払いいただきます。</p>
      <p>口座振替サービス提供会社はみずほファクター、SMBCファイナンスサービスいずれかからご選択いただきます。</p>
      <p>申込用紙は両方とも送付させていただきますので、どちらかをご選択いただき、ご記入、押印して、ご返送ください。</p>
    </div>

      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSave)">
          <div class="card biz-primary">
            <div class="card-header">
              申込用紙送付先
            </div>
            <div class="card-body">
              <p>送付先情報をご確認ください。変更がある場合は上書きしてください。ご確認いただきましたら、「申込用紙の送付依頼」ボタンを押してください。</p>
              <div class="biz-form-group seperated">
                <label class="biz-form-label required">郵便番号 (ハイフン有)</label>
                <div class="biz-form-body" style="display:flex;">
                  <b-form-group class="biz-input-group col-3">
                    <validation-provider name="郵便番号" rules="required|numeric|length:7" v-slot="{ errors }">
                      <b-form-input  placeholder="例）1060032" v-model="form.postalCode"/>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                  <div class="col-2">
                    <j-button class="ml-2" variant="light" @click="onAutoAddress()">住所自動入力</j-button>
                  </div>
                </div>
              </div>
              <div class="biz-form-group mt-2 seperated">
                <label class="biz-form-label required">住所</label>
                <div class="biz-form-body">
                  <b-form-group class="biz-input-group col-10">             
                    <validation-provider name="住所" rules="required|max:100" v-slot="{ errors }">
                      <b-form-input v-model="form.address"/>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="biz-form-group mt-2 seperated">
                <label class="biz-form-label required">会社名</label>
                <div class="biz-form-body">
                  <b-form-group class="biz-input-group col-8">
                    <validation-provider name="会社名" rules="required|max:50" v-slot="{ errors }">
                      <b-form-input placeholder="例）株式会社ワタベ" v-model="form.companyName"/>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="biz-form-group mt-2 seperated">
                <label class="biz-form-label required">部署名</label>
                <div class="biz-form-body">
                  <b-form-group class="biz-input-group col-8">
                    <validation-provider name="部署名" rules="required|max:50" v-slot="{ errors }">
                      <b-form-input placeholder="例）営業部" v-model="form.departmentName"/>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="biz-form-group mt-2">
                <label class="biz-form-label required">担当者</label>
                <div class="biz-form-body">
                  <b-form-group class="biz-input-group col-8">
                    <validation-provider name="担当者" rules="required|max:50" v-slot="{ errors }">
                      <b-form-input placeholder="例）鈴木太郎" v-model="form.personInCharge"/>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <j-button variant="primary" size="lg" buttonType="submit" >
              次へ
            </j-button>
          </div>
        </b-form>
      </ValidationObserver> 
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo';
  import AreaModel from '@/model/Area';
  
  export default {
    props: ["isRegistrationWizard"],
    components: {  },
    data: () => ({
      form: {}
    }),
    mounted: function(){
      CompanyInfo.getCompanyVerifyAddress(this.$store.state.user.companyCode).then(result => {
        this.form = result.data;
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    methods:{
      onSave(){
        CompanyInfo.saveCompanyVerifyAddress(this.$store.state.user.companyCode, this.form).then(() => {
          this.$successMessage("申込用紙送付先の登録が完了しました。");
          this.$emit('onSave', 'CategoryInfo');
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onAutoAddress(){
        let postalCode = this.form.postalCode.replaceAll(/-/g, '');
        AreaModel.getAddress(postalCode).then(result => {
          this.$set(this.form, "address", result.data.address);
        }).catch(reason => console.error(reason));
      }
    }
  }
</script>